<template>
  <v-app>
    <div class="baseBgSet">
      <!-- 讀取畫面 -->
      <v-overlay v-show="this.$store.state.loading" style="z-index: 999">
        <!-- <h2>Data is Loading...</h2> -->
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>

      <v-main>
        <router-view/>
      </v-main>

    </div>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
