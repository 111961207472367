import Vue from 'vue'
import VueRouter from 'vue-router'

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const includPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return includPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  { path: "*", redirect: "/" },
  { path: "/", redirect: "/Main" },
  {
    path: '/', name: 'index', component: resolve => require(['@/views/index.vue'], resolve),
    children: [
      { path: 'Main', name: 'main', component: resolve => require(['@/views/Main'], resolve) },
      { path: 'News/:class?/:snkey?', name: 'news', component: resolve => require(['@/views/News'], resolve), },
      { path: 'About/:class?', name: 'about', component: resolve => require(['@/views/About'], resolve), },
      { path: 'Event/:class?/:snkey?', name: 'event', component: resolve => require(['@/views/Event'], resolve), },
      { path: 'Data/:class?/:snkey?', name: 'data', component: resolve => require(['@/views/Data'], resolve), },
      { path: 'HealthInsurance/:class?/:snkey?', name: 'healthinsurance', component: resolve => require(['@/views/HealthInsurance'], resolve), },
      // { path: 'Colonoscopy/:class?/:snkey?', name: 'colonoscopy', component: resolve => require(['@/views/Colonoscopy'], resolve), },
      { path: 'Elearn/:class?/:snkey?', name: 'elearn', component: resolve => require(['@/views/Elearn'], resolve), },
      { path: 'Health/:class?/:snkey?', name: 'health', component: resolve => require(['@/views/Health'], resolve), },
      { path: 'Activities/:class?/:snkey?', name: 'activities', component: resolve => require(['@/views/Activities'], resolve), },
      { path: 'Weblink/:class?/:snkey?', name: 'weblink', component: resolve => require(['@/views/Weblink'], resolve), },
      // { path: 'Human/:class?/:snkey?', name: 'human', component: resolve => require(['@/views/Human'], resolve), },
      
      { path: 'Login', name: 'login', component: resolve => require(['@/views/Login'], resolve),   },
      { path: 'ContactUs', name: 'contactus', component: resolve => require(['@/components/ContactUs.vue'], resolve),   },
      { path: 'AuthNewMember', name: 'authnewmember', component: resolve => require(['@/components/AuthNewMember.vue'], resolve),   },
      { path: 'Personnel', name: 'personnel', component: resolve => require(['@/views/Personnel'], resolve),   },
      { path: 'CarouselOption', name: 'carouseloption', component: resolve => require(['@/views/CarouselOption'], resolve),   },
      { path: 'Recommend', name: 'recommend', component: resolve => require(['@/views/Recommend'], resolve), },

    ]
  },
  { path: '/month', name: 'month', component: resolve => require(['@/components/MonthTemplate'], resolve) },
  
  
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.meta.title != undefined) {
    window.document.title = to.meta.title;
  } else {
    window.document.title = '台灣胃腸神經與蠕動學會';
  }
  next()
})

export default router
